function adjustAllCircles() {
    const elements = document.querySelectorAll(".circle");

    for (i = 0; i < elements.length; i++) {
        const height = elements[i].clientHeight;

        if(elements[i].classList.contains('position-right')) {
            elements[i].style.marginLeft = `${(height / 2) * -1}px`;
        } else {
            elements[i].style.marginRight = `${(height / 2) * -1}px`;
        }
        elements[i].style.width = `${height}px`;
    }
}

adjustAllCircles();

window.addEventListener("resize", adjustAllCircles);
