window.onscroll = function() {myFunction()};

const navbar = document.getElementById("js_sticky-menu");
const sticky = navbar.offsetTop;

function myFunction() {
  if (window.pageYOffset >= sticky) {
    navbar.classList.add("sticky-menu")
  } else {
    navbar.classList.remove("sticky-menu");
  }
}