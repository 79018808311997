const activateLink = (navParent, removeAllChildren = '') => {
    const link = navParent.querySelector(".first-parent-link, .second-parent-link, .third-parent-link");
    const checkLink = navParent.querySelector(".js_nav-link");

    if (!link) {
        return;
    }

    if (checkLink.classList.contains("active")) {
        link.classList.add("active");
    }

    if (!navParent.closest('.mobile-nav')) {
        return;
    }

    const dropdownChild = navParent.querySelector('.first-child, .second-child, .third-child');

    if (!dropdownChild) {
        return;
    }

    link.addEventListener('click', event => {
        event.preventDefault();

        const shouldOpen = !dropdownChild.classList.contains("show");

        if (removeAllChildren) {
            document.querySelectorAll(removeAllChildren).forEach((child) => {
                child.classList.remove('show');
            });
        }

        if (shouldOpen) {
            dropdownChild.classList.add('show');
        }
    });
}

document.querySelectorAll(".first-parent").forEach((firstParent) => {
    activateLink(firstParent, '.first-child');

    firstParent.querySelectorAll(".second-parent").forEach((secondParent) => {
        activateLink(secondParent, '.second-child');

        secondParent.querySelectorAll(".third-parent").forEach((thirdParent) => {
            activateLink(thirdParent, '.third-child');
        });
    });
});