function adjustHeroHeightForMobile() {
    const imageElement = document.querySelector(".hero .background-image");
    const circleElement = document.querySelector(".hero .circle");

    if (imageElement && circleElement) {
        const height = circleElement.clientHeight;
        imageElement.style.marginTop = `-${height * 0.8}px`;
    }
}

adjustHeroHeightForMobile();
