const pwcLinkEls =document.querySelectorAll('.pwc-block .js_pwc-link');
const pwcMiddleLinkEls =document.querySelectorAll('.pwc-block .js_pwc-middle');
const pwcInnerLinkEls =document.querySelectorAll('.pwc-block .js_pwc-inner');
const pwcOuterLinkEls =document.querySelectorAll('.pwc-block .js_pwc-outer');

if (pwcLinkEls) {
    pwcLinkEls.forEach(pwcLink => {
        pwcLink.addEventListener('click', () => {
            window.open( `${window.location.origin}/${pwcLink.dataset.path}`,'_blank');
        })
    })
}

if (pwcMiddleLinkEls) {
    pwcMiddleLinkEls.forEach(pwcLink => {
        const pwcMiddleLinkBackgroundEl = document.querySelector(`.pwc-block .${pwcLink.id}-background`);
        const pwcMiddleLinkTextEl = document.querySelector(`.pwc-block .${pwcLink.id}-text`);
        const pwcChairEls = document.querySelectorAll(`.pwc-block .${pwcLink.id}-chair`);

        pwcLink.addEventListener('mouseover', () => {
            pwcMiddleLinkBackgroundEl.classList.add('active');
            pwcMiddleLinkTextEl.classList.add('active');

            pwcChairEls.forEach(el => {
                el.classList.add('active');
            })
        })
        pwcLink.addEventListener('mouseleave', () => {
            pwcMiddleLinkBackgroundEl.classList.remove('active');
            pwcMiddleLinkTextEl.classList.remove('active');

            pwcChairEls.forEach(el => {
                el.classList.remove('active');
            })
        })
    })
}

if (pwcInnerLinkEls) {
    pwcInnerLinkEls.forEach(pwcLink => {
        const pwcInnerLinkBackgroundEl = document.querySelector(`.pwc-block .${pwcLink.id}-background`);
        pwcLink.addEventListener('mouseover', () => {
            pwcInnerLinkBackgroundEl.classList.add('active');
        })
        pwcLink.addEventListener('mouseleave', () => {
            pwcInnerLinkBackgroundEl.classList.remove('active');
        })
    })
}

if (pwcOuterLinkEls) {
    pwcOuterLinkEls.forEach(pwcLink => {
        const pwcOuterLinkTextEls = document.querySelectorAll(`.pwc-block .${pwcLink.id}-text`);
        pwcLink.addEventListener('mouseover', () => {
            pwcOuterLinkTextEls.forEach(el => {
                el.classList.add('active');
            })

        })
        pwcLink.addEventListener('mouseleave', () => {
            pwcOuterLinkTextEls.forEach(el => {
                el.classList.remove('active');
            })
        })
    })
}
