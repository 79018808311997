

const elements = document.querySelectorAll(".splide");

for (i = 0; i < elements.length; i++) {
    new Splide( `#${elements[i].id}`, {
        type   : 'loop',
        perPage: 6,
        perMove: 1,
        pagination: false
    } ).mount();
}
