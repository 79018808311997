const videoWidgetEls = document.querySelectorAll('.js_video-widget');

const getOrigin = (url) => {
    if (url.includes('vimeo')) {
        return 'vimeo'
    }

    if (url.includes('youtube')) {
        return 'youtube'
    }

    return null;
}

const getVideoIdentifier = (url, origin) => {
    if ('vimeo' === origin) {
        const trimParams = url.split('?')[0];
        return trimParams.replace(/[^0-9]/g, '');
    }

    if ('youtube' === origin) {
        return url.replace('https://www.youtube.com/embed/', '');
    }
}

const getThumbnail = (origin, id) => {
    if ('vimeo' === origin) {
        return `https://vumbnail.com/${id}_high.jpg`
    }

    if ('youtube' === origin) {
        return `https://img.youtube.com/vi/${id}/maxresdefault.jpg`
    }
}

const replaceThumbnail = (thumbnailEl, thumbnail) => {
    thumbnailEl.querySelector('img').src = thumbnail;
}

if (videoWidgetEls) {
    videoWidgetEls.forEach(videoWidgetEl => {
        const source = videoWidgetEl.dataset.src;
        const thumbElement = videoWidgetEl.querySelector('.js_video-widget-thumbnail');
        const embedElement = videoWidgetEl.querySelector('.js_video-widget-embed');

        const videoOrigin = getOrigin(source);

        if(!videoOrigin) {
            thumbElement.remove();
            return;
        }

        const videoId = getVideoIdentifier(source, videoOrigin);
        const thumbnailSrc = getThumbnail(videoOrigin, videoId);

        replaceThumbnail(thumbElement, thumbnailSrc);

        thumbElement.addEventListener('click', () => {
            embedElement.src = `${embedElement.src}?autoplay=1`;
            thumbElement.remove();
        })
    })
}

