const allemployees = document.querySelectorAll(".employee");

allemployees.forEach((employee) => {
  const button = employee.querySelector(".button-employee");
  button.addEventListener('click', () => {
    const modal = employee.querySelector(".modal");
    modal.classList.add('modal--visible');

    const modalBox = employee.querySelector(".modal-box");
    modalBox.classList.add('is-open');

    if (modalBox.classList.contains('is-open')) {
      window.addEventListener('mouseup',function(event) {
        const modalContent = employee.querySelector(".modal-content");
        if(event.target != modalContent && event.target.parentNode != modalContent) {
          modalBox.classList.remove('is-open');
          modal.classList.remove('modal--visible');
        }
      });
    }
  });

  const buttonClose = employee.querySelector(".button-green-icon");
  buttonClose.addEventListener('click', () => {
    const modal = employee.querySelector(".modal");
    modal.classList.remove('modal--visible');

    const modalBox = employee.querySelector(".modal-box");
    modalBox.classList.remove('is-open');
  });
});
